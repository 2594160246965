import { gql } from '@apollo/client';

export const LoginWithPasswordMutation = gql`
  mutation loginWithPasswordMutation($email: String!, $password: String!) {
    authenticateUserWithPassword(email: $email, password: $password) {
      ... on UserAuthenticationWithPasswordSuccess {
        item {
          id
          email
          name
          phoneNumber
          isPhoneNumberVerified
          stripeVerificationSession {
            status
          }
          userParkings {
            id
            status
            parking {
              id
            }
          }
          organization {
            id
            name
          }
        }
        sessionToken
      }

      ... on UserAuthenticationWithPasswordFailure {
        message
      }
    }
  }
`;
